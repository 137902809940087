import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Deck from "gatsby-theme-mdx-deck/src/components/deck";
import splitSlides from "gatsby-theme-mdx-deck/src/split-slides";

export const pageQuery = graphql`
  query DeckQueryCustom($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    deck: deck(id: { eq: $id }) {
      id
      body
      slug
    }
  }
`;

const wrapper = props => {
  const slides = splitSlides(props);
  return <Deck {...props} slides={slides} />;
};

const components = {
  wrapper,
};

export default function Slide({
  data: {
    deck: { body, slug },
    site,
  },
  ...props
}) {
  const url = `${site.siteMetadata.siteUrl}/${slug}/`;
  const Component = props => <MDXRenderer {...props}>{body}</MDXRenderer>;

  return (
    <>
      <Helmet
        link={[
          {
            rel: "canonical",
            href: url,
          },
        ]}
      />
      <Component {...props} components={components} />
    </>
  );
}
